<template>
  <v-container id="test" fluid tag="section">
    <v-row>
      <v-col cols="auto">
        <v-card-actions class="pa-0">
          <v-spacer />
          <v-btn color="success" min-width="100" @click="refreshData"
            >Refresh
          </v-btn>
        </v-card-actions>
      </v-col>

      <v-col cols="auto">
        <v-card-actions class="pa-0">
          <v-spacer />
          <v-btn color="success" min-width="100" @click="bulkUpdate"
            >Bulk Update
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="itemsPerPage"
      :sort-by="[]"
      :sort-desc="[]"
      :server-items-length="totalItems"
      :options.sync="options"
      dense
      multi-sort
      :loading="loading"
      :footer-props="{
        itemsPerPageOptions: [20, 100],
      }"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-text-field
          v-model="searchItems.userId"
          :label="`${$t('user-id')} ${$t('search')}`"
          class="mx-4"
          @keyup.enter="searchUserId($event)"
        ></v-text-field>
      </template>
      <template v-slot:item.groupId="{ item }">
        <span :title="item.groupId">{{ renderGroupId(item.groupId) }}</span>
      </template>
      <template v-if="editEnabled" v-slot:item.editButtons="{ item }">
        <v-icon class="mr-2" @click="editItem(item)"> mdi-pencil</v-icon>
      </template>

      <template v-slot:body.append>
        <v-form ref="form" v-model="valid">
          <v-dialog v-model="dialogEdit" max-width="600px">
            <v-card>
              <v-card-title>
                <span class="headline">
                  {{ $t("trade-fee-rate") }} {{ $t("change") }}
                </span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  ENTER로 구분, 총 {{ countUserIds }} 건 (최대 100건 가능)
                </v-container>
                <v-container>
                  <v-textarea
                    v-model="userIdString"
                    outlined
                    :label="$t('user-id')"
                    required
                    :rules="ruleIds"
                  />
                </v-container>

                <v-divider></v-divider>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="editedItem.makerFeeRate"
                        :label="$t('maker')"
                        required
                        suffix="%"
                        :rules="ruleFeeRate"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="editedItem.takerFeeRate"
                        :label="$t('taker')"
                        required
                        suffix="%"
                        :rules="ruleFeeRate"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="editedItem.reason"
                        :label="$t('reason')"
                        required
                        :rules="ruleReason"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="gray darken-1" @click="dialogEdit = false"
                  >{{ $t("close") }}
                </v-btn>
                <v-btn
                  color="success darken-1"
                  :disabled="!valid"
                  @click="submitUpdate()"
                  >{{ $t("change") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-form>
      </template>
    </v-data-table>
    <v-snackbar v-model="snackbar" top centered>
      {{ notificationMessage }}
    </v-snackbar>
  </v-container>
</template>

<script>
import {
  searchItemsToFilters,
  getQueryString,
  filtersToSearchItems,
} from "@/utils/paginator";
import constants from "./constants";
import store from "@/store";

const ViewID = constants.VIEW_TRADE_FEE_RATE;
const Actions = {
  edit: "admin:TradeFeeRate:Edit",
};

const REGEX_FEE_RATE_PERCENT = /^-?\d+(\.\d+)*$/;
const REGEX_IDS_BY_ENTER = /^\d+(\n\d+)*$/;

export default {
  data: () => ({
    valid: false,
    dialogEdit: false,

    loading: true,
    options: {},
    itemsPerPage: 10,
    totalItems: 0,
    items: [],

    searchItems: {
      userId: null,
    },
    filters: "",

    editedItem: {},
    countUserIds: 0,
    userIdString: "",

    snackbar: false,
    notificationMessage: "",

    rules: {
      required: (v) => !!v || "required",
      number: (v) => REGEX_FEE_RATE_PERCENT.test(v) || "must be number",
      feeRange: (v) =>
        (v >= -0.2 && v <= 0.2) || "out of range ( -0.2 <= n <= 0.2 )",
      ids: (v) => REGEX_IDS_BY_ENTER.test(v.trim()) || "number and ENTER only",
    },

    permissions: [],
  }),
  computed: {
    headers() {
      const headersArr = [
        {
          text: this.$t("user-id"),
          value: "id",
          filterable: false,
          sortable: false,
        },
        {
          text: this.$t("email"),
          value: "email",
          filterable: false,
          sortable: false,
        },
        {
          text: this.$t("user-level"),
          value: "groupId",
          filterable: false,
          sortable: false,
        },
        {
          text: `${this.$t("maker")} ${this.$t("symbol.percent")}`,
          value: "makerFeeRate",
          filterable: false,
          sortable: false,
        },
        {
          text: `${this.$t("taker")} ${this.$t("symbol.percent")}`,
          value: "takerFeeRate",
          filterable: false,
          sortable: false,
        },
      ];

      // coditional column by edit permission
      if (this.editEnabled) {
        headersArr.push({
          text: this.$t("change"),
          value: "editButtons",
          filterable: false,
          sortable: false,
        });
      }
      return headersArr;
    },
    ruleIds() {
      return [this.rules.required, this.rules.ids];
    },
    ruleFeeRate() {
      return [this.rules.required, this.rules.number, this.rules.feeRange];
    },
    ruleReason() {
      return [this.rules.required];
    },
    editEnabled() {
      return this.permissions.indexOf(Actions.edit) >= 0;
    },
    renderGroupId() {
      return (groupId) => {
        // console.log("in renderGroupId:", groupId);
        switch (groupId) {
          case 100:
            return this.$t("trader");
          default:
            return "";
        }
      };
    },
  },
  watch: {
    options: {
      async handler() {
        if (this.$route.params.id) {
          this.searchItems.userId = this.$route.params.id;
          await this.searchUserId();
        }
        await this.loadData();
      },
      deep: true,
    },
    userIdString: {
      handler() {
        const userIdString = this.userIdString;
        const userIds = [...new Set(userIdString.split("\n").filter((v) => v))];
        // console.log("in handler, userIdString,", { userIdString, userIds });
        Object.assign(this.editedItem, { userIds });
        this.countUserIds = userIds.length;
      },
      deep: true,
    },
  },
  mounted() {
    Promise.all([this.getPermissions()]);
  },
  methods: {
    clearFilters() {
      this.filters = null;
    },

    /**
     * 현재 화면에 대한 권한을 로드한다.
     */
    async getPermissions() {
      try {
        // 백엔드에서 요청한 현재 화면에서 허용된 액션 목록
        const res = await this.authGet(`/api/auth/perm/views/${ViewID}`);
        this.permissions = res.data.data || [];

        // Store에 저장된 현재 화면에서 허용된 액션 목록
        const allowedActions = store.getters.getViewAllowedActions(ViewID);

        console.log("permissions: ", this.permissions);
        console.log("allowedActions: ", allowedActions);

        // TODO: 페이지가 마운트될 때마다 권한을 읽어올지, Store에 저장된 권한을 읽어올 지 선택 필요
      } catch (e) {
        this.processError(e);
      }
    },

    async refreshData() {
      await this.loadData(true);
      const refreshedPath = "/user/trade-fee-rate";
      const currentPath = this.$route.path;
      if (refreshedPath !== currentPath) {
        await this.$router.push(refreshedPath);
      }
    },

    async loadData(isRefresh = false) {
      try {
        if (isRefresh) {
          this.clearFilters();
        }
        this.loading = true;
        // console.log("options:", this.options);
        const { page, itemsPerPage: limits } = this.options;
        const queryString = getQueryString({
          limits,
          page,
          filters: this.filters,
        });

        const requestUrl = `/api/trade-fee-rate${queryString}`;
        const res = await this.authGet(requestUrl);
        this.items = res.data.data;
        this.totalItems = Number(res.data.totalItems);
        this.filters = res.data.filters;
        this.searchItems = filtersToSearchItems(this.filters);
        console.log("in refreshData,", {
          msal: this.$msal,
          page,
          limits,
          filters: this.filters,
          queryString,
          requestUrl,
          totalItems: this.totalItems,
          searchItems: this.searchItems,
        });
        this.loading = false;
      } catch (e) {
        const { response } = e;
        if (response) {
          this.showNotification(response.data);
        } else {
          this.showNotification(e);
        }
      }
    },

    editItem(item) {
      this.userIdString = item.id;
      Object.assign(this.editedItem, {
        userIds: [item.id],
        makerFeeRate: item.makerFeeRate,
        takerFeeRate: item.takerFeeRate,
      });
      this.dialogEdit = true;
    },

    async submitUpdate() {
      this.dialogEdit = false;
      try {
        const formData = {
          userIds: this.editedItem.userIds,
          makerFeeRate: this.editedItem.makerFeeRate,
          takerFeeRate: this.editedItem.takerFeeRate,
          reason: this.editedItem.reason,
        };

        // console.log("before update,", formData);
        // return false;
        // eslint-disable-next-line no-unreachable
        const res = await this.authPut(`/api/trade-fee-rate`, formData);
        // console.log("after update,", res);
        if (res.data.result) {
          await this.refreshData();
          this.showNotification("Updated");
        } else {
          const errorMsg =
            res.data.errorCode && res.data.errorMsg
              ? `failed, ${res.data.errorMsg}`
              : `failed, result: ${res.data.result}`;
          this.showNotification(errorMsg);
        }
      } catch (e) {
        console.error(e);
      }
    },

    processError(e) {
      const { response } = e;
      if (response) {
        this.showNotification(response.data);
      } else {
        console.error(e);
        this.showNotification(e);
      }
    },

    showNotification(msg) {
      this.notificationMessage = msg;
      this.snackbar = true;
    },

    async searchUserId() {
      // console.log(
      //   "searchUserId,",
      //   JSON.stringify({ searchItems: this.searchItems })
      // );
      this.filters = searchItemsToFilters(this.searchItems);
      await this.loadData();
    },

    async bulkUpdate() {
      this.userIdString = "";
      this.editedItem = {};
      this.dialogEdit = true;
    },
  },
};
</script>
